import React from 'react';
import { ButtonRightLogoProps } from './ButtonRightLogo.types';
import { StyledButton } from './ButtonRightLogo.styles';

const ButtonRightLogo: React.FC<ButtonRightLogoProps> = ({
  title,
  logo,
  onClick,
  href,
}) => {
  return (
    <StyledButton
      id="button-right-logo"
      onClick={onClick}
      size="small"
      endIcon={logo}
      href={href}
    >
      {title}
    </StyledButton>
  );
};

export default ButtonRightLogo;
