import React from 'react';
import {
  MenuItem,
  Pagination,
  PaginationItem,
  SelectChangeEvent,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DataGridProps,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import { SiteGridProps } from './SiteGrid.types';
import { SwitchDown, SwitchUp } from '@components/atoms/Icons/icons';
import { useTranslation } from 'react-i18next';
import {
  DynamicWidthDataGrid,
  PerPageContainer,
  SmallSelect,
} from './SiteGrid.styles';

export const Previous = () => {
  const { t } = useTranslation();
  return <>{t('action.previous')}</>;
};
export const Next = () => {
  const { t } = useTranslation();
  return <>{t('action.next')}</>;
};
export function CustomPagination() {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      id="portfolio-pagination"
      count={pageCount}
      page={page + 1}
      boundaryCount={isSmall ? 0 : 1}
      siblingCount={isSmall ? 0 : 1}
      renderItem={(item) => {
        return (
          <PaginationItem
            id={`portfolio-pagination-button-${item.page}`}
            slots={{ previous: Previous, next: Next }}
            {...item}
          />
        );
      }}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const SiteGrid: React.FC<
  SiteGridProps &
    Pick<
      DataGridProps,
      'columnVisibilityModel' | 'sortModel' | 'onSortModelChange' | 'columns'
    >
> = ({
  items,
  columnVisibilityModel,
  sortModel,
  onSortModelChange,
  columns,
  ...props
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { t } = useTranslation();

  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );

  const handleChange = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(event.target.value as number);
  };
  return (
    <>
      {isDesktop && (
        <PerPageContainer>
          <Typography variant="h3">
            {t('site.grid.show').toUpperCase()}
          </Typography>
          <SmallSelect<number>
            labelId="number-rows-select"
            id="portfolio-menu-table-rows"
            value={rowsPerPage}
            onChange={handleChange}
          >
            <MenuItem value={10}>{t('site.grid.rows', { rows: 10 })}</MenuItem>
            <MenuItem value={25}> {t('site.grid.rows', { rows: 25 })}</MenuItem>
            <MenuItem value={50}> {t('site.grid.rows', { rows: 50 })}</MenuItem>
            <MenuItem value={100}>
              {t('site.grid.rows', { rows: 100 })}
            </MenuItem>
          </SmallSelect>
        </PerPageContainer>
      )}
      <DynamicWidthDataGrid
        columnVisibilityModel={columnVisibilityModel}
        sortModel={sortModel}
        rows={items || []}
        columns={columns}
        pageSize={rowsPerPage}
        sortingOrder={['desc', 'asc']}
        autoHeight
        rowsPerPageOptions={[rowsPerPage]}
        disableSelectionOnClick
        onSortModelChange={onSortModelChange}
        localeText={{
          footerRowSelected: CustomPagination,
        }}
        disableColumnSort
        disableColumnSelector
        loading={!items}
        components={{
          Pagination: CustomPagination,
          ColumnSortedAscendingIcon: SwitchUp,
          ColumnSortedDescendingIcon: SwitchDown,
        }}
        {...props}
      />
    </>
  );
};

export default SiteGrid;
