import { Box, styled, Typography } from '@mui/material';

export const FilterButton = styled(Box)(({ theme, isSelected }) => ({
  backgroundColor: isSelected
    ? theme.palette.text.primary
    : theme.palette.neutral.contrastText,
  padding: theme.spacing(0.5, 1),
  cursor: 'pointer',
  boxShadow: theme.shadows[1],
  borderRadius: 30,
}));

export const FilterText = styled(Typography)(({ theme, isSelected }) => ({
  color: !isSelected
    ? theme.palette.text.primary
    : theme.palette.neutral.contrastText,
}));
