import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TitleIconResponsiveProps } from './TitleIconResponsive.types';
import { SectionIcon } from './TitleIconResponsive.styles';

export default function TitleIconResponsive({
  src,
  alt,
  children,
  marginIcon,
  variant = 'head',
  isSmall,
  ...props
}: TitleIconResponsiveProps) {
  return (
    <Stack alignItems="center" direction="row" spacing={0.5} {...props}>
      {src && (
        <SectionIcon
          marginIcon={marginIcon}
          variant={variant}
          src={src}
          alt={alt}
        />
      )}
      <Typography
        variant={
          variant === 'display'
            ? isSmall
              ? 'd6'
              : 'd4'
            : isSmall
            ? 'h2'
            : 'h1'
        }
      >
        {children}
      </Typography>
    </Stack>
  );
}
