import React from 'react';
import { HorizontalScrollView } from '@components/templates';
import { FilterButton, FilterText } from './QuickFilters.styles';
import { QuickFiltersProps } from './QuickFilters.types';
import { BoxProps } from '@mui/material';

const QuickFilters = <T extends { name: string }>({
  selected,
  items,
  onItemChange,
  nameExtractor,
  ...props
}: QuickFiltersProps<T> & BoxProps) => (
  <HorizontalScrollView
    items={items}
    keyExtractor={(filter) => filter.name}
    renderItem={(filter) => (
      <FilterButton
        id={`portfolio-${filter.name}`}
        key={filter.name}
        onClick={() => {
          onItemChange(filter);
        }}
        isSelected={nameExtractor(filter) === nameExtractor(selected)}
      >
        <FilterText
          variant="h5"
          isSelected={nameExtractor(filter) === nameExtractor(selected)}
        >
          {nameExtractor(filter).toUpperCase()}
        </FilterText>
      </FilterButton>
    )}
    {...props}
  />
);

export default QuickFilters;
