import React from 'react';
import { TitleSection, ValueViewContainer } from './ValueViewSection.styles';
import PortfolioRiskGradingSkeleton from '../PortfolioRiskGrading/PortfolioRiskGrading.loading';
import { Box, Skeleton, Typography } from '@mui/material';

const ValueViewSectionSkeleton: React.FC<{ isDesktop: boolean }> = ({
  isDesktop,
}) => {
  return (
    <Box>
      <Skeleton variant="text">
        <TitleSection>Title loading</TitleSection>
      </Skeleton>
      <ValueViewContainer>
        {isDesktop && (
          <Box>
            <Skeleton variant="text">
              <Typography variant="h5">Isorisk</Typography>
            </Skeleton>
            <Skeleton variant="rectangular" width={760} height={480} />
          </Box>
        )}
        <PortfolioRiskGradingSkeleton />
      </ValueViewContainer>
    </Box>
  );
};

export default ValueViewSectionSkeleton;
