import React from 'react';
import { PortfolioValueChartProps } from './PortfolioValueChart.types';
import {
  Box,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TitleIconResponsive } from '@components/molecules';
import { LegendChart } from '@components/molecules/portfolio/Chart/DonutChart/LegendChart';
import { extractBars } from '@components/molecules/portfolio/Chart/chartDef';
import { DonutChart } from '@components/molecules/portfolio/Chart/DonutChart';
import { Chart, ArcElement } from 'chart.js';
import {
  ChartContainer,
  ChartSectionContainer,
  TableLine,
  TitleContainer,
  WrongValueText,
} from './PortfolioValueChart.styles';
import { InfoTooltip } from '@components/atoms/InfoTooltip';

Chart.register(ArcElement);

const PortfolioValueChart: React.FC<PortfolioValueChartProps> = ({
  title,
  description,
  companyCurrency,
  titleValueDonut,
  children,
  tooltipDescription,
  wrongValueText,
  redirectionLink,
  errorMessage,
  onSendMessage,
  coachID,
  ...values
}) => {
  const bars = extractBars(values);
  const postMessage = () =>
    onSendMessage &&
    onSendMessage({
      contactId: coachID,
      message: errorMessage || '',
      subject: 'insured value data is wrong',
    });
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
      <TitleContainer>
        <TitleIconResponsive isSmall={isSmall} alt="values">
          {title}
          <InfoTooltip title={''} description={tooltipDescription} />
        </TitleIconResponsive>
        <Typography sx={{ paddingTop: '4px' }} variant="paragraphMedium">
          {description}
        </Typography>
      </TitleContainer>
      <ChartSectionContainer>
        <ChartContainer>
          <TableLine>
            <DonutChart
              titleValue={titleValueDonut}
              data={bars}
              total={values.total}
              currency={companyCurrency}
            />
          </TableLine>
          <LegendChart data={bars} currency={companyCurrency} />
        </ChartContainer>
        {wrongValueText && (
          <Box>
            <WrongValueText variant="paragraphSmall">
              {wrongValueText}{' '}
              <Link onClick={postMessage}>{redirectionLink}</Link>
            </WrongValueText>
          </Box>
        )}
      </ChartSectionContainer>
      {children}
    </Stack>
  );
};

export default PortfolioValueChart;
