import React from 'react';
import {
  Pagination,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DynamicWidthDataGrid,
  PerPageContainer,
  SmallSelect,
} from './SiteGrid.styles';
import { getColumnsLoading } from './datadef';
import { SwitchDown, SwitchUp } from '@components/atoms/Icons/icons';

const itemDataLoadingGrid = [1, 2, 3, 4, 5].map((item) => {
  return {
    id: item,
    name: 'Loading',
    address: 'Loading',
    country: 'Loading',
    riskGrade: 'Loading',
    siteValue: 'Loading',
    geocodingQuality: 'Loading',
  };
});
export function CustomPaginationLoading() {
  return (
    <Pagination
      id="portfolio-pagination"
      renderItem={() => {
        return <Skeleton width={20} />;
      }}
    />
  );
}

const SiteGridSkeleton = () => {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );
  return (
    <>
      {isDesktop && (
        <PerPageContainer>
          <Typography variant="h3">
            <Skeleton width={40} />
          </Typography>
          <Skeleton>
            <SmallSelect<number>
              labelId="number-rows-select"
              id="portfolio-menu-table-rows"
              value={25}
              onChange={() => ''}
            />
          </Skeleton>
        </PerPageContainer>
      )}
      <DynamicWidthDataGrid
        rows={itemDataLoadingGrid}
        columns={getColumnsLoading()}
        pageSize={6}
        sortingOrder={[]}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSort
        disableColumnSelector
        components={{
          Pagination: CustomPaginationLoading,
          ColumnSortedAscendingIcon: SwitchUp,
          ColumnSortedDescendingIcon: SwitchDown,
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'white',
          },
        }}
      />
    </>
  );
};

export default SiteGridSkeleton;
