import React from 'react';
import { PortfolioRiskListProps } from './PortfolioRiskList.types';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { TitleIconResponsive } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { ExpandableList } from '@components/templates/ExpandableList';
import { RiskLevel } from '@components/molecules';
import { RiskLevelProps } from '@components/molecules/portfolio/RiskLevel/RiskLevel.types';
import { allRisks } from '@utils/strings';
import {
  GraphContent,
  PortfolioRiskListContent,
} from './PortfolioRiskList.styles';
import { ExplanationSquare } from '@components/atoms/ExplanationSquare';

const PortfolioRiskList: React.FC<PortfolioRiskListProps> = ({
  id,
  openModal,
  description,
  redirectionText,
  sourceText,
  isPortfolioView,
  ...props
}) => {
  const { t } = useTranslation();
  const items: RiskLevelProps[] = allRisks
    ?.map((risk) => ({
      name: t(`portfolio.risk.types.${risk}`),
      value: (props[risk] || 0) as number,
    }))
    .sort((a, b) => b.value - a.value);

  items.unshift({
    name: t(`portfolio.risk.types.totalRisk`),
    value: props.totalHazard,
    color: 'ocean',
  });
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <PortfolioRiskListContent>
      <GraphContent isPortfolioView={isPortfolioView}>
        <TitleIconResponsive isSmall={isSmall} alt="risk">
          {t('portfolio.risk.title')}
        </TitleIconResponsive>

        <Typography
          variant="paragraphMedium"
          sx={{
            whiteSpace: 'pre-line',
          }}
        >
          {id === undefined ? t('portfolio.risk.description') : '\n'}
        </Typography>

        <ExpandableList
          showCount={isSmall ? 6 : 8}
          items={items}
          sx={{ mt: 2 }}
          buttonText={(isExpanded) =>
            t(isExpanded ? 'portfolio.risk.less' : 'portfolio.risk.more')
          }
          renderItem={(item) => <RiskLevel key={item.name} {...item} />}
        />
      </GraphContent>
      {(description || sourceText) && (
        <ExplanationSquare
          description={description}
          redirectionText={redirectionText}
          sourceText={sourceText}
          openModal={openModal}
        />
      )}
    </PortfolioRiskListContent>
  );
};

export default PortfolioRiskList;
