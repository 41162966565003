import React from 'react';
import { Typography } from '@mui/material';
import { LiveEventLabel } from '@components/atoms/LiveEventLabel';
import { EventStatusProps } from './EventStatus.types';
import { PasetEventWarningContainer, WarningLogo } from './EventStatus.styles';

const EventStatus: React.FC<EventStatusProps> = ({
  status,
  isAffectedSites,
  ...props
}) => {
  return status !== 'active' ? (
    isAffectedSites ? (
      <PasetEventWarningContainer>
        <WarningLogo src="/warning-past-event.svg" />
        <Typography variant="h5" {...props}>
          PAST EVENT
        </Typography>
      </PasetEventWarningContainer>
    ) : (
      <Typography variant="h5" {...props}>
        PAST EVENT
      </Typography>
    )
  ) : (
    <LiveEventLabel label="Live event" {...props} />
  );
};

export default EventStatus;
