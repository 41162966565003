import { sanitizeUrl } from '@braintree/sanitize-url';
import { ExpandableTypography } from '@components/atoms/ExpandableTypography';
import { PaperDetail } from '@components/templates';
import { Newspaper, OpenInNew } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import { getMonitoringEndDate, getStartedDate } from '@utils/date';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionContainer,
  ContainerDetail,
  NumberOfSitesContainer,
  ScrollBox,
  SitesAtRiskItem,
  SitesAtRiskSectionContainer,
} from './EventDetail.styles';
import { EventDetailProps } from './EventDetail.types';
import { DetailItemEvent } from '@components/atoms/DetailItemEvent';
import { currencyFormat } from '@utils/numbers';

const EventDetail: React.FC<EventDetailProps> = ({
  event,
  children,
  onZoomIn,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <PaperDetail>
      {children}
      <Typography variant="d6" sx={{ marginBottom: '8px' }}>
        {event.title}
      </Typography>
      {getStartedDate(event) && (
        <Typography variant="h5">
          {getStartedDate(event)?.label + ': ' + getStartedDate(event)?.value}
        </Typography>
      )}
      {getMonitoringEndDate(event) && (
        <Typography variant="h5">
          {getMonitoringEndDate(event)?.label +
            ': ' +
            getMonitoringEndDate(event)?.value}
        </Typography>
      )}
      <ScrollBox>
        <ContainerDetail>
          <DetailItemEvent type={t('liveevents.detail.type')}>
            <Typography variant="paragraphSmall">{event.perilName}</Typography>
          </DetailItemEvent>
          <DetailItemEvent type={t('liveevents.detail.subtype')}>
            <Typography variant="paragraphSmall">
              {event.subPerilName}
            </Typography>
          </DetailItemEvent>
          <DetailItemEvent type={t('liveevents.detail.source')}>
            <Link
              href={sanitizeUrl(event.link)}
              target="_blank"
              variant="paragraphSmall"
            >
              {t('liveevents.detail.copernicus')}{' '}
              {event?.externalId?.split(':')?.pop()}
            </Link>
          </DetailItemEvent>
          {event?.countrySites && (
            <SitesAtRiskSectionContainer>
              <DetailItemEvent type={t('liveevents.detail.risk-sites')}>
                <SitesAtRiskItem variant="paragraphSmall">
                  {event?.countrySites?.numberOfSites}
                </SitesAtRiskItem>
              </DetailItemEvent>
              <DetailItemEvent type={t('liveevents.detail.risk-value')}>
                <Typography variant="paragraphSmall">
                  {currencyFormat(event.countrySites?.sumOfTiv, currency)}
                </Typography>
              </DetailItemEvent>
            </SitesAtRiskSectionContainer>
          )}
        </ContainerDetail>
        {event.affectedSites && event.affectedSites?.numberOfSites > 0 && (
          <NumberOfSitesContainer>
            <Typography variant="h3" textTransform={'uppercase'}>
              {event.affectedSites?.numberOfSites}{' '}
              {t('liveevents.detail.sites-impacted')}
            </Typography>
          </NumberOfSitesContainer>
        )}
        <ExpandableTypography
          key={event.id}
          type={t('liveevents.detail.notes')}
        >
          {event.description}
        </ExpandableTypography>
        <ActionContainer>
          <Button
            id="event-detail-zoom"
            variant="contained"
            onClick={() => onZoomIn()}
          >
            {t('liveevents.detail.zoom-in')}
          </Button>
          <Button
            id="event-detail-news"
            variant="contained"
            color="secondary"
            target="_blank"
            endIcon={<Newspaper />}
            href={`https://www.google.com/search?q=${event.title}&tbm=nws`}
          >
            {t('liveevents.detail.find-news')}
          </Button>
          <Button
            id="event-detail-data"
            variant="contained"
            color="secondary"
            target="_blank"
            endIcon={<OpenInNew />}
            href={sanitizeUrl(event.link)}
          >
            {t('liveevents.detail.data')}
          </Button>
        </ActionContainer>
      </ScrollBox>
    </PaperDetail>
  );
};

export default EventDetail;
