import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import {
  MapViewContainer,
  MapViewImageContainer,
  MapViewImageSkeleton,
  TitleText,
} from './MapViewSection.styles';

export const MapViewSectionSkeleton = () => {
  return (
    <MapViewContainer>
      <Skeleton variant="text">
        <TitleText variant="h1">map view</TitleText>
      </Skeleton>
      <Box>
        <Skeleton variant="text">
          <Typography variant="paragraphMedium">
            Description map view component loading
          </Typography>
        </Skeleton>
      </Box>
      <MapViewImageContainer>
        <MapViewImageSkeleton variant="rectangular" />
      </MapViewImageContainer>
      <Skeleton variant="rectangular" width={'100%'} height={145} />
    </MapViewContainer>
  );
};
