import React from 'react';
import { ItemPortfolioRiskGradingProps } from './ItemPortfolioRiskGrading.types';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { ChipGrade } from '@components/atoms/ChipGrade';
import { BarGrading } from '@components/atoms/BarGrading';
import {
  BarItem,
  GradingContainer,
  ItemContainer,
  TitleItem,
} from './ItemPortfolioRiskGrading.styles';

const ItemPortfolioRiskGrading: React.FC<ItemPortfolioRiskGradingProps> = ({
  risk,
  siteNumber,
  siteTotalNumber,
  isExtreme,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <ItemContainer>
      <GradingContainer>
        <TitleItem>
          <ChipGrade
            variant={'outlined'}
            bold={isExtreme}
            label={isSmall ? `(${risk.grade})` : `${risk.label}(${risk.grade})`}
            color={risk.color}
          />
        </TitleItem>
        <BarItem>
          <BarGrading
            width={(siteNumber / siteTotalNumber) * 100}
            color={{
              center: risk.color,
              border: risk.color,
            }}
          />
        </BarItem>
      </GradingContainer>
      <Typography
        variant={'paragraphSmallSemi'}
        color={isExtreme ? risk.color : ''}
      >{`${siteNumber} LOCATIONS`}</Typography>
    </ItemContainer>
  );
};

export default ItemPortfolioRiskGrading;
