import React, { useCallback, useEffect, useState } from 'react';
import { PortfolioSiteListProps } from './PortfolioSiteList.types';
import { useMediaQuery, Theme, InputBase } from '@mui/material';
import { SiteGrid } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import {
  PortfolioFilter,
  getColumns,
  portfolioFilters,
} from '@components/molecules/portfolio/SiteGrid/datadef';
import {
  FilterTitle,
  QuickFilterCentered,
  SearchingLogo,
  SitesContainer,
  Title,
} from './PortfolioSiteList.styles';
import { UnmarginBox } from '@components/templates';
import { ModalGeocodingExplanation } from '../ModalGeocodingExplanation';
import { getColumnsPerCountry } from '@components/molecules/portfolio/SiteGrid/datadefCountry';

const PortfolioSiteList: React.FC<PortfolioSiteListProps> = ({
  items,
  initialSort,
  openModal,
  itemsCountry,
  companyCurrency,
  ...props
}) => {
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('tablet')
  );
  const [isOpenModalGeocoding, setIsOpenModalGeocoding] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [listItems, setListItems] = useState(items);
  const [filter, setFilter] = useState<PortfolioFilter | undefined>(
    initialSort !== null
      ? portfolioFilters.find((portfolioFilter) =>
          initialSort?.find(
            (sort) =>
              portfolioFilter.sort !== undefined &&
              sort.field === portfolioFilter?.sort[0].field &&
              sort.sort === portfolioFilter?.sort[0].sort
          )
        ) || portfolioFilters[matches ? 0 : 1]
      : portfolioFilters[matches ? 0 : 1]
  );
  const closeGeocodeExplanation = useCallback(
    () => setIsOpenModalGeocoding(false),
    [setIsOpenModalGeocoding]
  );
  const openGeocodeExplanation = useCallback(
    () => setIsOpenModalGeocoding(true),
    [setIsOpenModalGeocoding]
  );
  useEffect(() => {
    if (search) {
      const searchLower = search.toLowerCase().trim();
      setListItems(
        items?.filter((item) => {
          const addressString = `${item?.address?.street || ''}, ${
            item?.address?.city || ''
          }`.toLowerCase();
          return (
            item.name.toLowerCase().includes(searchLower) ||
            addressString.includes(searchLower)
          );
        })
      );
    } else {
      setListItems(items);
    }
  }, [search]);
  return (
    <SitesContainer {...props}>
      <ModalGeocodingExplanation
        isOpen={isOpenModalGeocoding}
        closeText={t('action.close')}
        onClose={closeGeocodeExplanation}
      />
      <Title variant="h1">{t('portfolio.title')}</Title>
      {filter?.name !== 'riskCountry' && (
        <InputBase
          placeholder={t('action.search') || ''}
          onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
          startAdornment={<SearchingLogo src="/search.svg" />}
          defaultValue={search}
        />
      )}
      <FilterTitle variant="h3">{t('action.quick')}</FilterTitle>
      <UnmarginBox>
        <QuickFilterCentered
          items={portfolioFilters}
          nameExtractor={(item: PortfolioFilter) =>
            t(`portfolio.filters.${item?.name}.name`)
          }
          selected={filter}
          onItemChange={setFilter}
        />
      </UnmarginBox>
      <SiteGrid
        columnVisibilityModel={filter?.columns}
        sortModel={filter?.sort}
        width={filter?.width}
        columns={
          filter?.name === 'riskCountry'
            ? getColumnsPerCountry(t, companyCurrency || '')
            : getColumns(
                t,
                companyCurrency || '',
                openModal,
                openGeocodeExplanation
              )
        }
        onSortModelChange={(sort) =>
          setFilter((prevFilter) => ({ ...prevFilter, sort }))
        }
        items={filter?.name === 'riskCountry' ? itemsCountry : listItems}
        openModal={openModal}
      />
    </SitesContainer>
  );
};

export default PortfolioSiteList;
