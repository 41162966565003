import React, { useCallback, useEffect, useState } from 'react';
import {
  ArticleCard,
  TopSection,
  RecommendationCard,
} from '@components/molecules';
import {
  ResourcesSection,
  SitesOverview,
  RiskCoach,
} from '@components/organisms';
import {
  GradientSection,
  PageSection,
} from '@components/templates/layouts/containers';
import { useCoach, useCompany } from '@modules/services/services-company';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Container,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useSitesCount } from '@modules/services/services-sites';
import { useAuth } from '@modules/services/services-auth';
import {
  useArticles,
  usePlaybooks,
} from '@modules/services/services-resources';
import { PlaybookCard } from '@components/molecules';
import { MorePlaybook } from '@components/atoms/Icons/icons';
import { RecommendationSummary, Resource } from '@modules/types';
import { useRecommendations } from '@modules/services/services-recommendations';
import { ArrowRightAlt, ContentPasteSearch } from '@mui/icons-material';
import DashboardLoader from './Dashboard.loading';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import { useOpenEvents } from '@modules/services/services-events';
import { EventCardDashboard } from '@components/molecules/events/EventCardDashboard';
import { Event } from '@modules/types/events.types';
import { SectionIcon } from '@components/atoms/InfoTooltip/InfoTooltip.styles';
import { Page } from '@components/templates';
import ButtonSites from '@components/atoms/ButtonSites/ButtonSites';
import { WelcomeModal } from '@components/molecules/dashboard/WelcomeModal';
import { useLocalState } from '@utils/functions';
import { noEventField } from '@components/storybook/mock';
import ResourcesSectionSkeleton from '@components/organisms/resources/ResourcesSection/ResourcesSection.loading';
import EventCardDashboardSkeleton from '@components/molecules/events/EventCardDashboard/EventCardDashboard.loading';

export const ScreenLoader = () => (
  <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
      flexGrow: 1,
      width: '100%',
    }}
  >
    <CircularProgress />
  </Container>
);

const Application: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data: coach } = useCoach();
  const { data: company } = useCompany();
  const { data: sitesCount } = useSitesCount();
  const { data: recommendations, isError: isRecommendationsError } =
    useRecommendations();
  const { data: articles, isError: isArticlesError } = useArticles();
  const { data: playbooks, isError: isPlaybooksError } = usePlaybooks();
  const { data: events, isError: isEventsError } = useOpenEvents();
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);
  const [welcomeAlreadyDisplayed, setWelcomeAlreadyDisplayed] = useLocalState(
    'welcome_already_displayed',
    false
  );

  useEffect(() => {
    !welcomeAlreadyDisplayed && setIsOpenWelcomeModal(true);
  }, [welcomeAlreadyDisplayed, setIsOpenWelcomeModal]);

  const onCloseModal = useCallback(() => {
    setIsOpenWelcomeModal(false);
    setWelcomeAlreadyDisplayed(true);
  }, []);

  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  const isLoading = !(
    company &&
    sitesCount &&
    (recommendations || isRecommendationsError) &&
    (articles || isArticlesError) &&
    (playbooks || isPlaybooksError)
  );

  if (isLoading) {
    return <DashboardLoader />;
  }

  return (
    <>
      <GradientSection>
        {!!user && (
          <TopSection
            headline={company?.description}
            title={t('dashboard.greating', { name: user.name })}
            action={
              <ButtonSites
                title={t('dashboard.sites')}
                number={sitesCount.totalSites}
              />
            }
          >
            {!isSmall && !!coach && company.coachEnabled && (
              <RiskCoach
                coachTitle={t('dashboard.coach.title')}
                coachDescription={t('dashboard.coach.description')}
                coachAction={t('dashboard.coach.action')}
                {...coach}
              />
            )}
          </TopSection>
        )}
      </GradientSection>
      <WelcomeModal
        isOpen={isOpenWelcomeModal}
        title={t('dashboard.modal.title')}
        description={t('dashboard.modal.description')}
        closeText={t('action.close')}
        onClose={onCloseModal}
      />
      <Page>
        {!!recommendations && (
          <ResourcesSection
            id="recommendation-section"
            icon={{ src: 'calendar.svg', alt: 'calendar' }}
            title={t('dashboard.recommendations.title') || ''}
            description={t('dashboard.recommendations.description')}
            items={recommendations?.items
              ?.slice(company?.hasNathazReport ? 0 : 1)
              .filter(({ enabled }) => enabled)}
            color={'default'}
            renderItem={(item: RecommendationSummary) => (
              <RecommendationCard
                key={item.id}
                {...item}
                href={`/suggestions/${item.id}`}
                actionIcon={<ArrowRightAlt />}
                action={t('action.start')}
              />
            )}
            leftAction={
              <Button
                id="recommendation-button-see-all"
                size="small"
                href="/suggestions"
                startIcon={<ContentPasteSearch />}
              >
                {t('dashboard.recommendations.action')}
              </Button>
            }
            empty={{
              title: t('dashboard.recommendations.empty.title'),
              description: t('dashboard.recommendations.empty.description'),
              renderEmpty: () => (
                <RecommendationCard
                  id="recommendation-empty"
                  key="recommendation-empty"
                  type="all-recommendations"
                  headline={t('dashboard.recommendations.empty.card.headline')}
                  description={t(
                    'dashboard.recommendations.empty.card.description'
                  )}
                  href={`/suggestions`}
                  actionIcon={<ContentPasteSearch />}
                  action={t('dashboard.recommendations.empty.card.action')}
                  enabled
                />
              ),
            }}
          />
        )}
        {isSmall && !!coach && company.coachEnabled && (
          <PageSection>
            <RiskCoach
              coachTitle={t('dashboard.coach.title')}
              coachDescription={t('dashboard.coach.description')}
              coachAction={t('dashboard.coach.action')}
              {...coach}
            />
          </PageSection>
        )}
        {events || isEventsError ? (
          <ResourcesSection
            id="live-events-section"
            paddingBottom={1}
            icon={{ src: 'live-events.svg', alt: 'live-events' }}
            title={t('dashboard.live-events.title') || ''}
            description={t('dashboard.live-events.description')}
            color={'default'}
            items={
              events && events.items && events.items.length > 0
                ? events.items
                : [noEventField]
            }
            breakpointsColumn={{
              mobile: 1,
              tablet: 2,
              desktop: 4,
            }}
            renderItem={(item: Event) => (
              <EventCardDashboard
                key={item.id}
                event={item}
                href={`/events/${item.id}`}
                action={t('dashboard.live-events.detail')}
                locationsTitle={t('dashboard.live-events.locations-number')}
                totalTitle={t('dashboard.live-events.total-impact')}
                currency={company.currencyIso3}
              />
            )}
            leftAction={
              <Button
                id="live-events-button-see-all"
                size="small"
                href="/events"
                startIcon={
                  <SectionIcon
                    id={'liveEvent'}
                    src={'/live-event-logo.svg'}
                    alt={'liveEvent'}
                  />
                }
              >
                {t('dashboard.live-events.action')}
              </Button>
            }
          />
        ) : (
          <ResourcesSectionSkeleton
            color="default"
            renderItem={(key) => <EventCardDashboardSkeleton key={key} />}
            breakpointsColumn={{
              mobile: 1,
              tablet: 2,
              desktop: 4,
            }}
            items={['1', '2', '3', '4']}
          />
        )}

        {!!sitesCount && (
          <PageSection color="primary">
            <SitesOverview
              count={sitesCount.totalSites}
              riskCount={sitesCount?.riskSites}
              href={'/portfolio'}
            />
          </PageSection>
        )}
        {articles !== undefined && articles?.articles?.length > 0 && (
          <ResourcesSection
            id="articles-section"
            paddingTop={4}
            icon={{ src: 'article.svg', alt: 'article' }}
            title={t('dashboard.articles.title') || ''}
            description={t('dashboard.articles.description')}
            items={articles?.articles}
            renderItem={(item: Resource) => (
              <ArticleCard
                key={item.title}
                articleButtonTitle={t('action.read')}
                {...item}
              />
            )}
          />
        )}
        {playbooks !== undefined && playbooks.playbooks.length > 0 && (
          <ResourcesSection
            id="playbooks-section"
            icon={{ src: 'playbook.svg', alt: 'playbooks' }}
            title={t('dashboard.playbooks.title') || ''}
            description={t('dashboard.playbooks.description')}
            titleTooltip={t('dashboard.playbooks.title')}
            descriptionTooltip={t('dashboard.playbooks.tooltip')}
            items={playbooks?.playbooks?.filter(({ disabled }) => !disabled)}
            renderItem={(item: Resource) => (
              <PlaybookCard
                key={item.title}
                playbookAvailableButtonTitle={t('action.read')}
                playbookUnavailableButtonTitle={t('action.coming-soon')}
                {...item}
              />
            )}
            leftAction={
              <Button
                id="playbooks-button-see-all"
                size="small"
                sx={{ marginBottom: 4 }}
                href="/riskguides"
                startIcon={<MorePlaybook />}
              >
                {t('dashboard.playbooks.action')}
              </Button>
            }
          />
        )}
      </Page>
    </>
  );
};

export default withErrorBoundary(Application);
