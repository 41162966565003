import { Box, Skeleton, styled, Typography } from '@mui/material';

export const MapViewContainer = styled(Box)(() => ({}));

export const MapViewImage = styled('img')(({ theme }) => ({
  margin: theme.spacing(2, 0, 1.75, 0),
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

export const MapViewImageContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 2,
}));

export const MapViewImageSkeleton = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.only('desktop')]: {
    width: 427,
    height: 374,
    margin: theme.spacing(2, 0),
  },
  [theme.breakpoints.only('tablet')]: {
    width: 640,
    height: 153,
    margin: theme.spacing(2, 0),
  },
  [theme.breakpoints.only('mobile')]: {
    width: 248,
    height: 374,
    margin: theme.spacing(2, 0),
  },
}));
