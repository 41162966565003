import { ChipGrade } from '@components/atoms/ChipGrade';
import { InfoTooltip } from '@components/atoms/InfoTooltip';
import { PerilRiskGrade, TotalRiskGrade } from '@modules/types';
import { ArrowForward } from '@mui/icons-material';
import { Button, Link, Skeleton, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { getRiskGrade, getRiskLevel } from '@utils/numbers';
import React from 'react';
import { TitleContainer } from './SiteGrid.styles';

export const renderRisk = (params: GridRenderCellParams<PerilRiskGrade>) => {
  if (params.value === undefined) return null;

  const riskLevel = getRiskLevel(params.value);

  return (
    <ChipGrade
      variant="outlined"
      bold={params.value === PerilRiskGrade.Extreme}
      {...riskLevel}
    />
  );
};

export const renderRiskGrade = (
  params: GridRenderCellParams<{ value: number; grade: TotalRiskGrade }>
) => {
  const riskGrade = getRiskGrade(params?.value?.grade);

  return (
    <ChipGrade
      variant="filled"
      label={riskGrade?.label}
      color={riskGrade?.color}
    />
  );
};

export const renderGeocodingQualityGrade = (geocodeQuality: number) => {
  return <img src={`/geocoding-quality-${geocodeQuality}.svg`} />;
};

export const renderSiteName = (params: GridRenderCellParams<string>) => {
  // todo:  use css instead
  const name = params?.value || '';
  const ellipsedText =
    name.length > 23 ? name.slice(0, 20).concat('...') : name;
  return (
    <Button
      id="portfolio-site-button-detail"
      sx={{
        textAlign: 'left',
        padding: 0,
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
        justifyContent: 'flex-start',
      }}
      onClick={() =>
        history.replaceState(
          null,
          '',
          `${window.location.pathname}?sort=summary`
        )
      }
      href={`${params.row.id}`}
      size="small"
      endIcon={<ArrowForward sx={{ fontSize: 30 }} fontSize="large" />}
    >
      {ellipsedText}
    </Button>
  );
};

export const renderCellSkeleton = () => {
  return <Skeleton width={'50px'} />;
};

export const renderHeaderCustom = (
  name: string | undefined,
  titlePopup: string,
  descriptionPopup: string,
  buttonText: string,
  openModal?: () => void
) => {
  return (
    <>
      <TitleContainer variant="h5">{name}</TitleContainer>
      <InfoTooltip title={titlePopup} description={descriptionPopup}>
        <Typography variant="paragraph">
          <Link onClick={openModal}>{buttonText}</Link>
        </Typography>
      </InfoTooltip>
    </>
  );
};
