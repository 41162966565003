import {
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
  useAuth0,
  User,
} from '@auth0/auth0-react';
import { throttle } from '@utils/functions';
import { useEffect } from 'react';
import { apiClient } from '.';
import { jwtDecode } from 'jwt-decode';

type DomainUser = User & {
  [key: string]: string;
};

export const useAuth = useAuth0<DomainUser>;

export const getTokenBuilder =
  (
    isAuthenticated: boolean,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    options?: GetTokenSilentlyOptions,
    callback?: () => void
  ) =>
  async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently(options);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const decodedToken = jwtDecode(token);
      const userRole =
        decodedToken &&
        //@ts-ignore
        decodedToken['https://iam.smart-service.axaxl.com/user_typology']
          ? //@ts-ignore
            decodedToken['https://iam.smart-service.axaxl.com/user_typology']
          : 'user_without_typology';
      localStorage.setItem('user_role', userRole);
      callback && callback();
    }
  };

export function useVerifyAuthenticationOnFocus() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  useEffect(() => {
    if (window.addEventListener) {
      const listener = throttle(
        getTokenBuilder(isAuthenticated, getAccessTokenSilently, {
          cacheMode: 'off',
        })
      );

      // Listen to visibillitychange and focus
      window.addEventListener('visibilitychange', listener, false);
      window.addEventListener('focus', listener, false);

      return () => {
        window.removeEventListener('visibilitychange', listener);
        window.removeEventListener('focus', listener);
      };
    }
  }, [isAuthenticated, getAccessTokenSilently]);
}
