import { QuickFilters } from '@components/molecules';
import QuickFiltersSkeleton from '@components/molecules/portfolio/QuickFilters/QuickFilter.loading';
import { Box, styled, Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only('desktop')]: {
    marginBottom: theme.spacing(3),
  },
  marginBottom: theme.spacing(2),
}));

export const FilterTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.noEvent.main,
  textTransform: 'uppercase',
  marginTop: theme.spacing(2),
}));

export const SitesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const CenteredContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    width: 668,
    flex: 1,
    alignSelf: 'center',
  },
}));

export const QuickFilterCentered = styled(QuickFilters)(() => ({}));

export const QuickFilterCenteredLoading = styled(QuickFiltersSkeleton)(
  ({ theme }) => ({
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'center',
    },
  })
);

export const SearchingLogo = styled('img')(({ theme }) => ({
  padding: theme.spacing(0, 1.4),
}));
