import { ResponsiveDirectionBox } from '@components/templates';
import React from 'react';
import { PortfolioRiskList } from '../PortfolioRiskList';
import { PortfolioValueChart } from '../PortfolioValueChart';
import { PortfolioRiskValueProps } from './PortfolioRiskValue.types';
import { MapViewSection } from '../MapViewSection';

const PortfolioRiskValue: React.FC<PortfolioRiskValueProps> = ({
  riskTooltip,
  valueDescription,
  valueTitle,
  titleValueDonut,
  children,
  openModal,
  companyCurrency,
  siteValueDescription,
  description,
  redirectionText,
  sourceText,
  wrongValueText,
  redirectionLink,
  onSendMessage,
  errorMessage,
  coachID,
  mapViewTitle,
  mapViewDescription,
  mapViewItemSquare1,
  mapViewItemSquare2,
  mapViewTitleSquare,
  isPortfolioView,
  ...props
}) => {
  return (
    <ResponsiveDirectionBox>
      <PortfolioRiskList
        {...props}
        tooltip={riskTooltip}
        openModal={openModal}
        description={description}
        redirectionText={redirectionText}
        sourceText={sourceText}
        isPortfolioView={isPortfolioView}
      />
      {props.total && props.total > 0 && (
        <PortfolioValueChart
          {...props}
          description={valueDescription}
          title={valueTitle}
          titleValueDonut={titleValueDonut}
          companyCurrency={companyCurrency}
          tooltipDescription={siteValueDescription}
          wrongValueText={wrongValueText}
          redirectionLink={redirectionLink}
          onSendMessage={onSendMessage}
          errorMessage={errorMessage}
          coachID={coachID}
        >
          {children}
        </PortfolioValueChart>
      )}
      {isPortfolioView && (
        <MapViewSection
          title={mapViewTitle}
          description={mapViewDescription}
          titleSquare={mapViewTitleSquare}
          itemSquare1={mapViewItemSquare1}
          itemSquare2={mapViewItemSquare2}
        />
      )}
    </ResponsiveDirectionBox>
  );
};

export default PortfolioRiskValue;
