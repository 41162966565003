import React, { useEffect, useMemo, useRef } from 'react';
import { LiveMapProps } from './LiveMap.types';
import MapGL, { Layer, Popup, Source } from 'react-map-gl';
import { DEFAULT_MAP_PROPS } from '@utils/map';
import MapEventMarker from '@components/atoms/MapEventMarker/MapEventMarker';
import { ZoomControl } from '@components/molecules/locationMap/ZoomControl';
import { PopupDelineationContainer, StartPanel } from './LiveMap.styles';
import { pointSitesLiveLayer } from '../GlobalMap/layers';
import {
  areaOfInterestBorderLayer,
  areaOfInterestLayer,
  delineationLayer,
} from './layers';
import { MapSitePopup } from '@components/molecules/locationMap/MapSitePopup';
import { TooltipMapMessage } from '@components/atoms/TooltipMapMessage';
import '../GlobalMap/GlobalMap.css';
import { useTheme } from '@mui/material';
import { mergeFootprints } from '@utils/geojson';
import { MapSiteDrawer } from '@components/molecules/locationMap/MapSiteDrawer';
import { useTranslation } from 'react-i18next';
import { MapFooterAttributes } from '@components/molecules/locationMap/MapFooterAttributes';
import { usePortfolioMap } from '../GlobalMap/useSiteMap';
import { mapFilters } from '../GlobalMap/filters';
import { grey } from '@mui/material/colors';
import { TooltipPosition } from '@components/atoms/TooltipMapMessage/TooltipMapMessage.styles';

const LiveMap: React.FC<LiveMapProps> = ({
  locationsMap,
  data,
  totalValue,
  selected,
  hoveredFeature,
  children,
  onClick,
  isSmall,
  onMove,
  noLiveEventsText,
  displayedEvents,
  numberOfSites,
  companyCurrency,
}) => {
  const eventsFootprints = useMemo(
    () => mergeFootprints(displayedEvents),
    [displayedEvents]
  );
  const popupRef = useRef<mapboxgl.Popup>(null);
  const affectedSiteMap = usePortfolioMap(
    selected?.affectedSites &&
      selected?.affectedSites?.sites &&
      selected?.affectedSites?.sites.length > 0
      ? selected?.affectedSites.sites
      : [],
    mapFilters
  );
  const countrySiteMap = usePortfolioMap(
    selected?.countrySites &&
      selected?.countrySites?.sites &&
      selected?.countrySites?.sites.length > 0
      ? selected?.countrySites.sites
      : [],
    mapFilters
  );

  useEffect(() => {
    popupRef.current?.trackPointer();
  }, [popupRef.current, selected]);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <MapGL
      {...DEFAULT_MAP_PROPS}
      reuseMaps={false}
      ref={locationsMap.mapRef}
      interactiveLayerIds={['delineation', 'unclustered-point']}
      attributionControl={false}
      onClick={locationsMap.onClick}
      onMouseEnter={locationsMap.onMouseEnter}
      onMouseLeave={locationsMap.onMouseLeave}
      onMove={onMove}
      mapStyle={'mapbox://styles/mapbox/light-v11'}
      style={{ position: 'relative', boxShadow: theme.shadows[1] }}
    >
      {displayedEvents && (
        <Source
          key="events"
          id="events"
          type="geojson"
          generateId
          data={eventsFootprints}
        >
          <Layer {...areaOfInterestLayer} />
          <Layer {...areaOfInterestBorderLayer} />
          <Layer {...delineationLayer} />
        </Source>
      )}

      {countrySiteMap &&
        countrySiteMap.locationsSource &&
        selected &&
        // selected.status === 'active' &&
        selected.countrySites && (
          <Source
            id="point-sites"
            type="geojson"
            generateId
            data={countrySiteMap.locationsSource}
          >
            <Layer
              id="unclustered-point"
              {...pointSitesLiveLayer(totalValue, numberOfSites, grey[600])}
            />
          </Source>
        )}
      {affectedSiteMap &&
        affectedSiteMap.locationsSource &&
        selected &&
        // selected.status === 'active' &&
        selected.affectedSites && (
          <Source
            id="point-sites-affected"
            type="geojson"
            generateId
            data={affectedSiteMap.locationsSource}
          >
            <Layer
              id="point-sites-affected"
              {...pointSitesLiveLayer(
                totalValue,
                numberOfSites,
                theme.palette.affectedSites.main
              )}
            />
          </Source>
        )}

      {data?.reverse().map((event) => (
        <MapEventMarker
          key={event.id}
          event={event}
          onClick={onClick}
          isSelected={selected && event.id === selected.id}
          numberSitesImpacted={event.affectedSites?.numberOfSites}
          isDisplay={
            !(selected && event.id === selected.id) ||
            (locationsMap?.mapRef?.current?.getZoom() || 0) < 5.5
          }
        />
      ))}
      {selected && selected.status !== 'active' && (
        <MapEventMarker
          key={selected.id}
          event={selected}
          isSelected
          onClick={onClick}
          numberSitesImpacted={0}
        />
      )}

      {data && data.length === 0 && !selected && (
        <TooltipPosition>
          <TooltipMapMessage text={noLiveEventsText} />
        </TooltipPosition>
      )}
      <ZoomControl smallMargin />

      <StartPanel>{children}</StartPanel>
      {locationsMap.selectedLocation &&
        locationsMap.selectedLocation.length > 0 &&
        (isSmall ? (
          <MapSiteDrawer
            open={!!locationsMap.selectedLocation[0]}
            onClose={locationsMap.unselectLocation}
            site={locationsMap.selectedLocation[0]}
            companyCurrency={companyCurrency}
          />
        ) : (
          <MapSitePopup
            onClose={locationsMap.unselectLocation}
            sites={locationsMap.selectedLocation}
            anchor={isSmall ? 'bottom' : 'left'}
            companyCurrency={companyCurrency}
          />
        ))}
      {hoveredFeature && hoveredFeature?.properties?.kind === 'delineation' && (
        <Popup
          ref={popupRef}
          longitude={hoveredFeature.lnglat.lng}
          latitude={hoveredFeature.lnglat.lat}
          closeButton={false}
          closeOnClick={false}
        >
          <PopupDelineationContainer variant="paragraph">
            {t('liveevents.popup.delineation')}
          </PopupDelineationContainer>
        </Popup>
      )}
      <MapFooterAttributes />
    </MapGL>
  );
};

export default LiveMap;
