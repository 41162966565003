import React from 'react';
import { HorizontalScrollView } from '@components/templates';
import { FilterButton } from './QuickFilters.styles';
import { QuickFiltersProps } from './QuickFilters.types';
import { BoxProps, Skeleton } from '@mui/material';

const QuickFiltersSkeleton = <T extends { name: string }>({
  items,
}: QuickFiltersProps<T> & BoxProps) => (
  <HorizontalScrollView
    keyExtractor={(items) => items.name}
    items={items}
    renderItem={() => (
      <Skeleton>
        <FilterButton id={`portfolio-quick-filter-lading`} variant={''}>
          Loading
        </FilterButton>
      </Skeleton>
    )}
  />
);

export default QuickFiltersSkeleton;
