import { MapScaleFilter } from '@components/molecules/locationMap/MapFiltersControl/MapFiltersControl.types';
import { SiteMapFeature, SiteSummary } from '@modules/types';
import { Event } from '@modules/types/events.types';
import { PerilRiskGrade, TotalRiskGrade } from '@modules/types/risks.types';

export const siteMockStoryBook: SiteMapFeature = {
  address: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
  addressSite: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
  resolutionSite: 'rooftop',
  stockTypeSite: '',
  axaNaceCodeSite: '',
  buildingsNumber: 2,
  buildingLegalStatus: '',
  buildingMaterialsSite: '',
  coastalFlood: PerilRiskGrade.Negligible,
  confidence: '',
  earthquake: PerilRiskGrade.Low,
  extraTropicalCyclone: PerilRiskGrade.Negligible,
  hailstorm: PerilRiskGrade.Medium,
  id: '483e008d-1bb1-4f31-a8f0-92ae45e9e53d',
  lat: 47.86251,
  lightning: PerilRiskGrade.Medium,
  lon: 5.932432,
  name: 'Retail store',
  resolution: 'COUNTRY',
  riskGrade: TotalRiskGrade.A,
  riverineFlood: PerilRiskGrade.Low,
  siteValue: 600000,
  surfaceWaterFlood: PerilRiskGrade.Low,
  tornado: PerilRiskGrade.Low,
  tropicalCyclone: PerilRiskGrade.Extreme,
  tsunami: PerilRiskGrade.Low,
  volcano: PerilRiskGrade.High,
  wildfire: PerilRiskGrade.Low,
  geocodeQuality: 2,
};

export const siteTwoMockStoryBook: SiteMapFeature = {
  address: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
  addressSite: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
  resolutionSite: 'rooftop',
  stockTypeSite: '',
  axaNaceCodeSite: '',
  buildingsNumber: 2,
  buildingLegalStatus: '',
  buildingMaterialsSite: '',
  coastalFlood: PerilRiskGrade.Low,
  confidence: '',
  earthquake: PerilRiskGrade.Low,
  extraTropicalCyclone: PerilRiskGrade.Low,
  hailstorm: PerilRiskGrade.Medium,
  id: '483e008d-1bb1-4f31-a8f0-92ae45e9e53d',
  lat: 47.76251,
  lightning: PerilRiskGrade.Medium,
  lon: 5.832432,
  name: 'Retail store',
  resolution: 'ROOFTOP',
  riskGrade: TotalRiskGrade.A,
  riverineFlood: PerilRiskGrade.Low,
  siteValue: 1160000000,
  surfaceWaterFlood: PerilRiskGrade.Low,
  tornado: PerilRiskGrade.Low,
  tropicalCyclone: PerilRiskGrade.Low,
  tsunami: PerilRiskGrade.Low,
  volcano: PerilRiskGrade.Low,
  wildfire: PerilRiskGrade.Low,
  geocodeQuality: 5,
};

export const affectedSiteMockStoryBook: SiteSummary = {
  addressSite: {
    street: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
    city: 'NYC',
    country: 'USA',
    postalcode: '23456',
    countryIso2: 'US',
  },
  address: {
    street: '5540 Gulf of Mexico Dr, Longboat Key, 34228, United States',
    city: 'NYC',
    country: 'USA',
    postalcode: '23456',
    countryIso2: 'US',
  },
  categoryRiskGradeSummary: {
    geophysical: PerilRiskGrade.Low,
    hydrological: PerilRiskGrade.Low,
    meteorological: PerilRiskGrade.Low,
    wildfire: PerilRiskGrade.Low,
  },
  perilGradeSummary: {
    surfaceWaterFlood: PerilRiskGrade.Low,
    tornado: PerilRiskGrade.Low,
    tropicalCyclone: PerilRiskGrade.Low,
    tsunami: PerilRiskGrade.Low,
    volcano: PerilRiskGrade.Low,
    wildfire: PerilRiskGrade.Low,
    earthquake: PerilRiskGrade.Low,
    extraTropicalCyclone: PerilRiskGrade.Low,
    hailstorm: PerilRiskGrade.Medium,
    coastalFlood: PerilRiskGrade.Low,
    lightning: PerilRiskGrade.Medium,
    riverineFlood: PerilRiskGrade.Low,
  },
  riskGrade: TotalRiskGrade.A,
  geocodeQuality: 4,
  resolutionSite: 'rooftop',
  stockTypeSite: '',
  axaNaceCodeSite: '',
  buildingsNumber: 2,
  buildingLegalStatus: '',
  buildingMaterialsSite: '',
  confidence: '',
  id: '483e008d-1bb1-4f31-a8f0-92ae45e9e53d',
  lat: 47.76251,
  lon: 5.832432,
  name: 'Retail store',
  resolution: 'ROOFTOP',
  insuredValues: {
    total: 1160000000,
  },
  isoriskCoordinates: {
    x: 10,
    y: 10000,
  },
  source: 'BING',
};

export const filterNaturalHazardStoryBook: MapScaleFilter = {
  field: 'riskGrade',
  legend: { top: 'High risk', bottom: 'Low risk' },
  property: 'riskGrade',
  scale: [
    { color: '#C91432', label: 'Risk Grade E', value: 'E' },
    { color: '#FF9900', label: 'Risk Grade D', value: 'D' },
    { color: '#F2CE0D', label: 'Risk Grade C', value: 'C' },
    { color: '#8AB957', label: 'Risk Grade B', value: 'B' },
    { color: '#138636', label: 'Risk Grade A', value: 'A' },
  ],
  type: 'scale',
};

export const filterGeocodingQualityStoryBook: MapScaleFilter = {
  type: 'scale',
  field: 'geocodingQuality',
  property: 'resolution',
  legend: { top: 'Low resolution', bottom: 'High resolution' },
  scale: [
    {
      color: '#C91432',
      label: 'Country',
      value: ['STATE', 'COUNTRY', 'NO_GEOCODE'],
    },
    {
      color: '#FF9900',
      label: 'Zipcode or City',
      labelMobile: 'Zipcode',
      value: ['CITY', 'ZIPCODE'],
    },
    {
      color: '#F2CE0D',
      label: 'Street centroid',
      labelMobile: 'Centroid',
      value: ['CLIENT_COORDINATES', 'STREET_CENTROID'],
    },
    {
      color: '#8AB957',
      label: 'Street',
      labelMobile: 'Street',
      value: 'STREET',
    },
    {
      color: '#138636',
      label: 'Rooftop',
      value: ['PARCEL_CENTROID', 'ROOFTOP'],
    },
    {
      color: '#138636',
      label: 'Verified',
      value: ['VERIFIED'],
    },
  ],
};

export const filterRiskPerPerilsStoryBook: MapScaleFilter = {
  type: 'scale',
  field: 'riverineFloodRiskGrade',
  property: 'riverineFloodRiskGrade',
  parentField: 'perils',
  legend: { top: 'High risk', bottom: 'Low risk' },
  scale: [
    {
      color: '#C91432',
      label: 'High',
      value: 'high',
    },
    {
      color: '#F2CE0D',
      label: 'Moderate',
      value: 'medium',
    },
    {
      color: '#138636',
      label: 'Low',
      value: 'low',
    },
  ],
};

export const mapStylesStoryBook = [
  {
    id: 'map-streets',
    style: '/map-style-streets-v11.json',
    icon: '/map-streets.jpg',
    label: 'Streets',
  },
  {
    id: 'map-satellite',
    style: 'mapbox://styles/mapbox/satellite-v9',
    icon: '/map-satellite.jpg',
    label: 'Satellite',
  },
  {
    id: 'map-light',
    style: 'mapbox://styles/mapbox/light-v11',
    icon: '/map-light.jpg',
    label: 'Light',
  },
];

export const playbookStoryBook = {
  id: '638398',
  title: 'Cutting, welding and other hot work',
  url: 'http://distorted-cappuccino.com',
  categories: [{ name: 'Human Element', id: '1' }],
  image: {
    url: 'https://blog.but.fr/wp-content/uploads/2019/12/01-choisir-cafe-grain.jpg',
    description: 'This is a coffee ',
  },
};

export const liveEventsStorybook: Event[] = [
  {
    id: '6bdbf68e-60f2-4c3c-9d2f-9e6eba635af4',
    countries: ['IT'],
    description:
      'An area of low pressure coming from North Africa will bring, from tomorrow, widespread and persistent rainfall with very strong winds over Sicily, especially in the south-eastern areas. Heavy rains are expected that could cause rivers to rise and flood especially over the cities of Catania, Syracuse, and Ragusa. The national warning system has issued a red alert over this area. The event is expected to peak on February 09 afternoon and last until Friday February 10 afternoon',
    startedAt: '2023-02-09T12:00:00+00:00',
    externalId: 'CEMS:EMSR649',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR649',
    latitude: 15.26,
    longitude: 36.96,
    publicationDate: '2023-02-09T06:25:39+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'active',
    title: 'Flood in Sicily, Italy',
    perilName: 'Flood',
    subPerilName: 'Tropical cyclone',
    category: 'hydrological',
    affectedSites: {
      sites: [affectedSiteMockStoryBook],
      sumOfTiv: 1234000,
      numberOfSites: 1,
    },
  },
  {
    id: '310e5fa8-a0ca-4bca-b6b0-123b29bdea20',

    countries: ['TR'],
    description:
      'A 7.8-magnitude quake struck just after 4 a.m. Monday morning local time, 23 kilometers (14.2 miles) east of Nurdagi, Gaziantep province. It is one of the strongest earthquakes to hit Republic of Türkiye in more than 100 years. The event caused dozens of collapsed buildings and fatalities. GDACS ID: EQ1357591.',
    startedAt: '2023-02-06T01:17:00+00:00',
    externalId: 'CEMS:EMSR648',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR648',
    latitude: 37.64918,
    longitude: 36.78096,
    publicationDate: '2023-02-06T04:35:13+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'active',
    title: 'Earthquake in East Anatolian Fault Zone',
    perilName: 'Earthquake',
    subPerilName: 'Tropical cyclone',
    category: 'geophysical',
  },
  {
    id: '95e68358-e93c-430d-ac57-f32ca5834396',

    countries: ['CL'],
    description:
      'In the last weeks (January- February 2023), Chile was heavily affected by serious forest fires/wild fires. On 5 January Chile requested support from UCPM Member States/ Participating States to limit the consequences of the destructive fires. The EMS Copernicus  service for satellite maps was triggered in support to operations in the affected areas.',
    startedAt: '2023-02-05T00:00:00+00:00',
    externalId: 'CEMS:EMSR647',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR647',
    latitude: -73.27394,
    longitude: -37.38724,
    publicationDate: '2023-02-05T22:08:23+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'active',
    title: 'Forest Fires in Chile',
    perilName: 'Wildfire',
    subPerilName: 'Tropical cyclone',
    category: 'wildfire',
  },
  {
    id: '2f284126-92ba-42cb-b85a-8c7d9a867bbd',

    countries: ['XK'],
    description:
      'Floods occurred on 19th and 20th of January 2023 in northern, eastern and western Kosovo*. The situation has calmed down, but the damages caused by the floods and their consequences remain. The Copernicus Emergency Management Service - Rapid Mapping has been activated in order to estimate the flood impact on agriculture and urban areas for emergency support.  * This designation is without prejudice to positions on status, and is in line with UNSCR 1244/1999 and the ICJ Opinion on the Kosovo declaration of independence.',
    startedAt: '2023-01-19T19:00:00+00:00',
    externalId: 'CEMS:EMSR646',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR646',
    latitude: 20.6,
    longitude: 42.72,
    publicationDate: '2023-01-30T13:27:32+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'closed',
    title: 'Flood in Kosovo',
    perilName: 'Flood',
    subPerilName: 'Tropical cyclone',
    category: 'hydrological',
  },
  {
    id: '6f8b234b-c601-438a-bb95-1aa8bc5e15fb',

    countries: ['MG'],
    description:
      'Tropical Storm Cheneso reached the Northeastern coast of Madagascar, in the Sava Region, on the morning of 19 January. Wind gusts of up to 120 km/h were recorded as the storm headed South-West, in addition to heavy rainfall reported particularly in the North, triggering flash flooding and multiple landslides. Approximately 700 homes were damaged, affecting over 2000 people, along with one fatality and one missing person. Numbers are expected to rise as more reports come in from rural areas.  For the Diana, Sava and Anakanjirofo regions (Northeastern coast), a red alert was issued, implying an imminent threat of further torrential rainfall and extreme flooding. Copernicus EMS is activated to provide flood extent and damage assessment.',
    startedAt: '2023-01-19T13:45:00+00:00',
    externalId: 'CEMS:EMSR645',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR645',
    latitude: 46.99683,
    longitude: -18.36605,
    publicationDate: '2023-01-25T10:48:19+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'closed',
    title: 'Tropical Storm Cheneso in Madagascar',
    perilName: 'Storm',
    subPerilName: 'Tropical cyclone',
    category: 'meteorological',
    affectedSites: {
      sites: [affectedSiteMockStoryBook],
      sumOfTiv: 1234000,
      numberOfSites: 1,
    },
  },
  {
    id: '6bdbf68e-60f2-4c3c-9d2f-9e6eba635af5',
    countries: ['IT'],
    description:
      'An area of low pressure coming from North Africa will bring, from tomorrow, widespread and persistent rainfall with very strong winds over Sicily, especially in the south-eastern areas. Heavy rains are expected that could cause rivers to rise and flood especially over the cities of Catania, Syracuse, and Ragusa. The national warning system has issued a red alert over this area. The event is expected to peak on February 09 afternoon and last until Friday February 10 afternoon',
    startedAt: '2023-02-09T12:00:00+00:00',
    externalId: 'CEMS:EMSR649',
    link: 'http://emergency.copernicus.eu/mapping/list-of-components/EMSR649',
    latitude: 15.26,
    longitude: 36.96,
    publicationDate: '2023-02-09T06:25:39+00:00',
    provider: 'Copernicus EMS Rapid Mapping Activations',
    status: 'active',
    title: 'Flood in Sicily, Italy',
    perilName: 'Flood',
    subPerilName: 'Tropical cyclone',
    category: 'hydrological',
    affectedSites: {
      sites: [affectedSiteMockStoryBook],
      sumOfTiv: 1234000,
      numberOfSites: 1,
    },
  },
];

export const IDEmptyEvent = 'no-ongoing-event-id';

export const noEventField: Event = {
  id: IDEmptyEvent,
  countries: [],
  description: '',
  startedAt: '',
  externalId: '',
  link: '',
  latitude: 0,
  longitude: 0,
  publicationDate: '',
  provider: '',
  status: 'closed',
  title: 'No events at this time',
  perilName: 'Live events',
  subPerilName: '',
  category: 'other',
};

export const companyStorybook = {
  id: '6f8b234b-c601-438a-bb95-1aa8bc5e15fb',
  description: 'Company A',
  coachEnabled: true,
  riskScanningEnabled: true,
  coachId: '6f8b234b-c601-438a-bb95-1aa8bc5e15ff',
  currencyId: '6f8b234b-c601-438a-bb95-1aa8bc5e15fh',
  currencyIso3: 'USD',
  hasNathazReport: true,
  tokenKey: '6f8b234b-c601-438a-bb95-1aa8bc5e15fj',
};

export const riskDataMock = {
  totalSites: 20,
  riskSites: 10,
  riskGradeSummary: {
    a: 12,
    b: 3,
    c: 17,
    d: 5,
    e: 10,
  },
};
