import React from 'react';
import {
  BarGradingContainer,
  RiskGradingContainer,
} from './PortfolioRiskGrading.styles';
import { Skeleton, Typography } from '@mui/material';
import ItemPortfolioRiskGradingSkeleton from '@components/molecules/portfolio/ItemPortfolioRiskGrading/ItemPortfolioRiskGrading.loading';

const PortfolioRiskGradingSkeleton = () => {
  return (
    <>
      <RiskGradingContainer>
        <Skeleton>
          <Typography textTransform="uppercase" variant="h3">
            {'Titleloading'}
          </Typography>
        </Skeleton>
        <BarGradingContainer>
          <ItemPortfolioRiskGradingSkeleton />
          <ItemPortfolioRiskGradingSkeleton />
          <ItemPortfolioRiskGradingSkeleton />
          <ItemPortfolioRiskGradingSkeleton />
          <ItemPortfolioRiskGradingSkeleton />
        </BarGradingContainer>
      </RiskGradingContainer>
    </>
  );
};

export default PortfolioRiskGradingSkeleton;
