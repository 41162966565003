import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GradientSection } from '@components/templates/layouts/containers';
import { PageContent } from '@components/templates/layouts/pages';
import { useTranslation } from 'react-i18next';
import { TopSection } from '@components/molecules';
import { PortfolioSiteList } from '@components/organisms';
import {
  useCountryList,
  useIsoRiskSitesRisk,
  usePortfolioRisk,
  usePortfolioValue,
  useSiteList,
  useSitesCount,
} from '@modules/services/services-sites';
import { PortfolioRiskValue } from '@components/organisms/portfolio/PortfolioRiskValue';
import { useSearchParams } from 'react-router-dom';
import { sortParams } from '@utils/strings';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import PortfolioSkeleton from './Portfolio.loading';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import { useCompany } from '@modules/services/services-company';
import { SMART_SERVICES_RISK_SCANNING } from '@utils/urls';
import { ModalRisksTutorial } from '@components/organisms/portfolio/ModalRisksTutorial';
import { ValueViewSection } from '@components/organisms/portfolio/ValueViewSection';
import { SectionsContainer } from './Portfolio.styles';
import { SquareItem } from '@components/molecules/portfolio/SquareItem';
import { ButtonRightLogo } from '@components/atoms/ButtonRightLogo';
import { ArrowForward } from '@mui/icons-material';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { data: company } = useCompany();
  const { data: portfolioRisk } = usePortfolioRisk();
  const { data: portfolioValue } = usePortfolioValue();
  const { data: portfolioItems } = useSiteList();
  const { data: isoRiskItems } = useIsoRiskSitesRisk();
  const { data: countryItems } = useCountryList();
  const { data: sitesCount } = useSitesCount();
  const [searchParams] = useSearchParams();
  const [isOpenTuto, setIsOpenTuto] = useState<boolean>(false);
  const sort = searchParams.get('sort');
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );

  const gridRef = useRef<HTMLInputElement>(null);
  const executeScroll = () => {
    if (sort !== null && sort !== 'summary' && portfolioItems !== undefined) {
      setTimeout(() => gridRef?.current?.scrollIntoView(), 200);
    }
  };

  useEffect(executeScroll, [sort, portfolioItems]);
  const closeTutorial = useCallback(
    () => setIsOpenTuto(false),
    [setIsOpenTuto]
  );
  const openTutorial = useCallback(() => setIsOpenTuto(true), [setIsOpenTuto]);

  if (
    !(
      portfolioRisk &&
      portfolioValue &&
      portfolioItems &&
      sitesCount &&
      isoRiskItems
    )
  ) {
    return <PortfolioSkeleton />;
  }

  const initialSort = sortParams(sort);

  return (
    <>
      <ModalRisksTutorial
        isOpen={isOpenTuto}
        closeText={'Close'}
        onClose={closeTutorial}
      />
      <GradientSection color="ocean">
        <TopSection
          back
          backText={t('navigation.dashboard')}
          headline={t('portfolio.title')}
          title={company?.description}
        />
      </GradientSection>
      <>
        <PageContent>
          <SectionsContainer>
            <Box>
              <PortfolioRiskValue
                {...portfolioRisk}
                {...portfolioValue}
                openModal={openTutorial}
                riskTooltip={{
                  title: t('portfolio.risk.tooltip.title'),
                  description: t('portfolio.risk.tooltip.description'),
                }}
                valueTitle={t('portfolio.value.title')}
                valueDescription={t('portfolio.value.description')}
                titleValueDonut={t('portfolio.value.tiv')}
                displayExplanation={true}
                companyCurrency={company?.currencyIso3}
                siteValueDescription={t('portfolio.value.site-value')}
                description={t('portfolio.risk.tooltip.description')}
                redirectionText={t('portfolio.tutorial.open_tutorial')}
                sourceText={t('footer.risk-attributes')}
                mapViewTitle={t('portfolio.tab.all_sites_section.title')}
                mapViewDescription={t(
                  'portfolio.tab.all_sites_section.description_map'
                )}
                mapViewTitleSquare={t(
                  'portfolio.tab.all_sites_section.sites_risk'
                )}
                mapViewItemSquare1={
                  <Typography variant="h1">
                    {sitesCount.riskGradeSummary.e}
                  </Typography>
                }
                mapViewItemSquare2={
                  <ButtonRightLogo
                    title={t('portfolio.map.action')}
                    logo={<ArrowForward />}
                    href={'/portfolio/map'}
                  />
                }
                isPortfolioView={true}
              >
                {company?.riskScanningEnabled && (
                  <SquareItem
                    title={t('portfolio.risk_scanning.title')}
                    item1={
                      <Typography variant="paragraphMedium">
                        {t('portfolio.risk_scanning.description')}
                      </Typography>
                    }
                    item2={
                      <ButtonRightLogo
                        title={t('portfolio.risk_scanning.redirection')}
                        logo={<img src={'/open.svg'} />}
                        href={SMART_SERVICES_RISK_SCANNING}
                      />
                    }
                  />
                )}
              </PortfolioRiskValue>
              <ValueViewSection
                isDesktop={isDesktop}
                titleSection={t('portfolio.iso_risk_matrix.title-section')}
                titleBottomAxisIsoRiskMatrix={t(
                  'portfolio.iso_risk_matrix.title-axis-bottom'
                )}
                titleLeftAxisIsoRiskMatrix={t(
                  'portfolio.iso_risk_matrix.title-axis-left'
                )}
                siteList={isoRiskItems.items}
                percentile90={isoRiskItems.percentile90}
                YAxisIsoRiskMatrix={isoRiskItems.yAxis}
                YAxisLabelIsoRiskMatrix={isoRiskItems.yAxisLabel}
                titleGraph={t('portfolio.risk_grading.title-graph')}
                titleIsoRiskMatrix={t('portfolio.iso_risk_matrix.title-matrix')}
                riskData={sitesCount}
                currency={company?.currencyIso3 || ''}
              />
            </Box>
            <Box ref={gridRef}>
              <PortfolioSiteList
                initialSort={initialSort}
                items={portfolioItems?.items}
                itemsCountry={countryItems?.items}
                openModal={openTutorial}
                companyCurrency={company?.currencyIso3}
              />
            </Box>
          </SectionsContainer>
        </PageContent>
      </>
    </>
  );
};

export default withErrorBoundary(Screen);
