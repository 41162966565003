import React from 'react';
import { PortfolioRiskGradingProps } from './PortfolioRiskGrading.types';
import { riskColors } from '@utils/numbers';
import { ItemPortfolioRiskGrading } from '@components/molecules/portfolio/ItemPortfolioRiskGrading';
import {
  BarGradingContainer,
  RiskGradingContainer,
  TitleText,
} from './PortfolioRiskGrading.styles';
// import { Box } from '@mui/material';

const PortfolioRiskGrading: React.FC<PortfolioRiskGradingProps> = ({
  titleGraph,
  riskData,
}) => {
  return (
    <>
      <RiskGradingContainer>
        <TitleText textTransform="uppercase" variant="h5">
          {titleGraph}
        </TitleText>
        <BarGradingContainer>
          <ItemPortfolioRiskGrading
            risk={riskColors.negligible}
            siteNumber={riskData.riskGradeSummary.a}
            siteTotalNumber={riskData.totalSites}
          />
          <ItemPortfolioRiskGrading
            risk={riskColors.low}
            siteNumber={riskData.riskGradeSummary.b}
            siteTotalNumber={riskData.totalSites}
          />
          <ItemPortfolioRiskGrading
            risk={riskColors.medium}
            siteNumber={riskData.riskGradeSummary.c}
            siteTotalNumber={riskData.totalSites}
          />
          <ItemPortfolioRiskGrading
            risk={riskColors.high}
            siteNumber={riskData.riskGradeSummary.d}
            siteTotalNumber={riskData.totalSites}
          />
          <ItemPortfolioRiskGrading
            risk={riskColors.extreme}
            siteNumber={riskData.riskGradeSummary.e}
            siteTotalNumber={riskData.totalSites}
            isExtreme={true}
          />
        </BarGradingContainer>
      </RiskGradingContainer>
    </>
  );
};

export default PortfolioRiskGrading;
