import { ResponsiveDirectionBox } from '@components/templates';
import React from 'react';
import PortfolioRiskListSkeleton from '../PortfolioRiskList/PortfolioRiskList.loading';
import PortfolioValueChartSkeleton from '../PortfolioValueChart/PortfolioValueChart.loading';
import { PortfolioResponsiveSeparator } from './PortfolioRiskValue.styles';
import { MapViewSectionSkeleton } from '../MapViewSection/MapViewSection.loading';

const PortfolioRiskValueSkeleton: React.FC<{
  displayFeedbackLinkSkeleton?: boolean;
  isPortfolioView?: boolean;
}> = ({ displayFeedbackLinkSkeleton = false, isPortfolioView }) => {
  return (
    <ResponsiveDirectionBox>
      <PortfolioRiskListSkeleton isPortfolioView={isPortfolioView} />
      <>
        <PortfolioResponsiveSeparator />
        <PortfolioValueChartSkeleton
          displayFeedbackLinkSkeleton={displayFeedbackLinkSkeleton}
        />
        {isPortfolioView && <MapViewSectionSkeleton />}
      </>
    </ResponsiveDirectionBox>
  );
};

export default PortfolioRiskValueSkeleton;
