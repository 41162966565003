import { useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import {
  EventContainer,
  LiveEventItemContainer,
  LiveEventLabelContainer,
  ReviewButton,
  TitleEvent,
} from './EventCard.styles';
import { EventCardProps } from './EventCard.types';
import { LiveEventIcon } from '@components/atoms/LiveEventIcon';
import { ArrowForward } from '@mui/icons-material';
import { getColorsLiveEvent } from '@utils/colors';
import { EventStatus } from '../EventStatus';
import { getMainEventDate } from '@utils/date';
import { ItemCard } from '@components/atoms/ItemCard';

const EventCard: React.FC<EventCardProps> = ({
  id,
  event,
  onClick,
  isSelected,
}) => {
  const theme = useTheme();
  const isOpenEvent = event?.status === 'active';
  const isEventWithAffectedSites: boolean =
    event.affectedSites && event.affectedSites?.sites.length > 0 ? true : false;
  const color = getColorsLiveEvent(theme, event, isEventWithAffectedSites);
  const handleClick = useCallback(() => onClick(event.id), [event]);

  return (
    <EventContainer
      id={id}
      onClick={handleClick}
      color={color}
      elevation={isSelected ? 2 : 1}
    >
      <LiveEventIcon name={event.category} color={color} />
      <LiveEventItemContainer>
        <LiveEventLabelContainer>
          <EventStatus
            status={event?.status}
            isAffectedSites={isEventWithAffectedSites}
          />
        </LiveEventLabelContainer>
        <TitleEvent
          variant="h5"
          color={isEventWithAffectedSites ? theme.palette.text.primary : color}
        >
          {event.title.toUpperCase()}
        </TitleEvent>
        <ItemCard
          title={getMainEventDate(event)?.label || 'Date started'}
          value={getMainEventDate(event)?.value || ''}
        />
        <ReviewButton
          id={'event-card-action'}
          size="small"
          color={
            isOpenEvent && !isEventWithAffectedSites
              ? event.category
              : undefined
          }
          endIcon={<ArrowForward />}
          onClick={handleClick}
        >
          Review
        </ReviewButton>
      </LiveEventItemContainer>
    </EventContainer>
  );
};

export default EventCard;
