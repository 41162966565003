import { FlexColumnBox } from '@components/templates';
import { Typography, styled } from '@mui/material';

export const TitleText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.only('desktop')]: {
    marginBottom: theme.spacing(4),
  },
}));

export const RiskGradingContainer = styled(FlexColumnBox)(({ theme }) => ({
  flex: '1 1 300px',
  [theme.breakpoints.only('tablet')]: {
    paddingTop: theme.spacing(3),
  },
  [theme.breakpoints.only('mobile')]: {
    paddingTop: theme.spacing(2),
  },
}));

export const BarGradingContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: theme.spacing(7.5),
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2),
  },
}));

export const TitleSection = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}));
