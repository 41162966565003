import { Link, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { extractCountry } from '../../../../utils';
import { TitleIconResponsive } from '../../navigation/TitleIconResponsive';
import {
  AdressSectionContainer,
  InLineText,
  InfoContainer,
  InfoContent,
  InfoItemContainer,
  OffsetLogoContainer,
  PreLineText,
  WrongAddressButton,
} from './InfoDetails.styles';
import { InfoDetailsProps } from './InfoDetails.types';
import { renderGeocodingQualityGrade } from '../SiteGrid/SiteCells';
import { ModalGeocodingExplanation } from '@components/organisms/portfolio/ModalGeocodingExplanation';
import { InfoTooltip } from '@components/atoms/InfoTooltip';

const InfoItem: React.FC<{
  src: string;
  separator?: boolean;
  title: string;
  alt: string;
  children?: React.ReactElement | React.ReactElement[];
  titleTooltip?: string;
  descriptionTooltip?: string;
  linkTooltip?: string;
  openModal?: () => void;
}> = ({
  src,
  title,
  separator,
  children,
  titleTooltip,
  descriptionTooltip,
  linkTooltip,
  openModal,
  alt,
  ...props
}) => {
  return (
    <InfoItemContainer {...props}>
      <TitleIconResponsive marginIcon src={src} alt={alt}>
        {title}
        {titleTooltip && (
          <InfoTooltip
            title={titleTooltip}
            description={descriptionTooltip || ''}
          >
            <Typography variant="paragraph">
              <Link onClick={openModal}>{linkTooltip}</Link>
            </Typography>
          </InfoTooltip>
        )}
      </TitleIconResponsive>
      <InfoContent borderBottom={separator}>{children}</InfoContent>
    </InfoItemContainer>
  );
};

const InfoDetails: React.FC<InfoDetailsProps> = ({
  address,
  identifier,
  occupancy,
  location,
  dataQuality,
  fieldAddress,
  fieldId,
  fieldName,
  fieldOccupancy,
  fieldLocation,
  fieldGeocodingQuality,
  fieldSource,
  geocodingQuality,
  source,
  wrongAdressTitle,
  onSendMessage,
  isLoading,
  errorMessage,
  coachID,
  closeGeocodingModal,
  titleTooltip,
  descriptionTooltip,
  linkTooltip,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const [isOpenModalGeocoding, setIsOpenModalGeocoding] =
    useState<boolean>(false);

  const postMessage = () =>
    onSendMessage({
      contactId: coachID,
      message: errorMessage,
      subject: 'location data is wrong',
    });
  const closeGeocodeExplanation = useCallback(
    () => setIsOpenModalGeocoding(false),
    [setIsOpenModalGeocoding]
  );
  const openGeocodeExplanation = useCallback(
    () => setIsOpenModalGeocoding(true),
    [setIsOpenModalGeocoding]
  );
  return (
    <InfoContainer id="site-component-details">
      <ModalGeocodingExplanation
        isOpen={isOpenModalGeocoding}
        closeText={closeGeocodingModal}
        onClose={closeGeocodeExplanation}
      />
      <InfoItem src="/pin.svg" alt="risk" title={fieldAddress} separator>
        <AdressSectionContainer>
          <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
            {address?.street || address?.city ? (
              address?.street && address?.city && address?.postalcode ? (
                <>
                  {address?.street}
                  {'\n'}
                  {address?.city}
                  {'\n'}
                  {address?.postalcode}
                </>
              ) : (
                address?.street || address?.city
              )
            ) : (
              'No address'
            )}
            {'\n'}
            {extractCountry(address.countryIso2)}
          </PreLineText>
          <WrongAddressButton
            id="individual-site-button-wrong-address"
            variant="contained"
            size={isSmall ? 'medium' : 'small'}
            onClick={postMessage}
            disabled={isLoading}
          >
            {wrongAdressTitle}
          </WrongAddressButton>
        </AdressSectionContainer>
      </InfoItem>

      <InfoItem src="/details.svg" alt="risk" title={fieldLocation} separator>
        <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldId}
          </InLineText>{' '}
          {identifier}
          {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldName}
          </InLineText>{' '}
          {location} {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldOccupancy}
          </InLineText>{' '}
          {occupancy}
          {'\n'}
        </PreLineText>
      </InfoItem>
      <InfoItem
        src="/data-quality.svg"
        alt="risk"
        title={dataQuality}
        titleTooltip={titleTooltip}
        descriptionTooltip={descriptionTooltip}
        linkTooltip={linkTooltip}
        openModal={openGeocodeExplanation}
      >
        <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldGeocodingQuality + ':'}
          </InLineText>
          <OffsetLogoContainer>
            {renderGeocodingQualityGrade(geocodingQuality)}
          </OffsetLogoContainer>{' '}
          {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldSource}
          </InLineText>{' '}
          {source}
          {'\n'}
        </PreLineText>
      </InfoItem>
    </InfoContainer>
  );
};

export default InfoDetails;
