import React from 'react';
import { MapViewSectionProps } from './MapViewSection.types';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  MapViewContainer,
  MapViewImage,
  MapViewImageContainer,
  TitleText,
} from './MapViewSection.styles';
import { SquareItem } from '@components/molecules/portfolio/SquareItem';

const MapViewSection: React.FC<MapViewSectionProps> = ({
  title,
  description,
  titleSquare,
  itemSquare1,
  itemSquare2,
}) => {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('tablet')
  );
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );
  return (
    <MapViewContainer>
      <TitleText variant="h1">{title}</TitleText>
      <Box>
        <Typography variant="paragraphMedium">{description}</Typography>
      </Box>
      <MapViewImageContainer>
        <MapViewImage
          src={
            isDesktop
              ? '/map-view-desktop.png'
              : isTablet
              ? '/map-view-tablet.png'
              : '/map-view-mobile.png'
          }
        />
      </MapViewImageContainer>
      <SquareItem title={titleSquare} item1={itemSquare1} item2={itemSquare2} />
    </MapViewContainer>
  );
};

export default MapViewSection;
