import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import RiskLevelListSkeleton from '@components/molecules/portfolio/RiskLevel/RiskLevelList.loading';
import TitleIconResponsiveSkeleton from '@components/molecules/navigation/TitleIconResponsive/TitleIconResponsive.loading';
import {
  ExplanationSquareSkeleton,
  GraphContent,
  PortfolioRiskListContent,
} from './PortfolioRiskList.styles';

const PortfolioRiskListSkeleton: React.FC<{ isPortfolioView?: boolean }> = ({
  isPortfolioView,
}) => {
  return (
    <PortfolioRiskListContent>
      <GraphContent isPortfolioView={isPortfolioView}>
        <TitleIconResponsiveSkeleton marginIcon src="/risk.svg" alt="risk">
          <Skeleton width={70} />
        </TitleIconResponsiveSkeleton>
        <Typography
          variant="paragraphMedium"
          sx={{
            whiteSpace: 'pre-line',
          }}
        >
          <Skeleton width={150} variant="text" />
        </Typography>
        <RiskLevelListSkeleton />
      </GraphContent>
      <ExplanationSquareSkeleton variant="rectangular" />
    </PortfolioRiskListContent>
  );
};

export default PortfolioRiskListSkeleton;
