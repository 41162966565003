import { FlexBox } from '@components/templates';
import { styled } from '@mui/material';

export const PasetEventWarningContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: 30,
  color: theme.palette.neutral.contrastText,
  backgroundColor: theme.palette.text.primary,
}));

export const WarningLogo = styled('img')(({ theme }) => ({
  margin: theme.spacing(0, 1, 0, 0),
}));
