import { Box, styled } from '@mui/material';

export const HorizontalContainer = styled(Box)(
  ({ theme, marginSpacing = 0.25, spacing = 2 }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    justifyContent: 'flex-start',
    [theme.breakpoints.only('mobile')]: {
      padding: `8px ${theme.spacing(marginSpacing)}`,
    },
    [theme.breakpoints.only('tablet')]: {
      padding: `8px ${theme.spacing(marginSpacing)}`,
    },
    [theme.breakpoints.only('desktop')]: {
      padding: `16px ${theme.spacing(0.25)}`,
    },
    ['& > *:not(:last-child)']: {
      marginRight: theme.spacing(spacing),
      [theme.breakpoints.only('mobile')]: {
        marginRight: theme.spacing(2),
      },
    },
  })
);

export const HorizontalItem = styled(Box)`
  display: inline-flex;
  flex: 0 0 auto;
`;
