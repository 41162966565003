import React from 'react';
import { PortfolioSiteListProps } from './PortfolioSiteList.types';
import { Typography, Skeleton, InputBase } from '@mui/material';
import {
  QuickFilterCenteredLoading,
  SitesContainer,
} from './PortfolioSiteList.styles';
import { UnmarginBox } from '@components/templates';
import SiteGridSkeleton from '@components/molecules/portfolio/SiteGrid/SiteGrid.loading';

const PortfolioSiteListSkeleton: React.FC<PortfolioSiteListProps> = () => {
  return (
    <SitesContainer>
      <Typography variant="h1">
        <Skeleton width={100} />
      </Typography>
      <Skeleton sx={{ width: '100%', maxWidth: 720 }}>
        <InputBase
          placeholder={''}
          onChange={() => console.log('loading')}
          defaultValue={''}
        />
      </Skeleton>
      <Typography variant="h3">
        <Skeleton width={120} />
      </Typography>
      <UnmarginBox>
        <QuickFilterCenteredLoading items={[1, 2, 3, 4, 5, 6]} />
      </UnmarginBox>
      <SiteGridSkeleton />
    </SitesContainer>
  );
};

export default PortfolioSiteListSkeleton;
