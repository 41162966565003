import React from 'react';
import {
  Item1Container,
  Item2Container,
  SquareContainer,
} from './SquareItem.styles';
import { Typography } from '@mui/material';
import { SquareItemProps } from './SquareItem.types';

const SquareItem: React.FC<SquareItemProps> = ({
  title,
  textColor,
  item1,
  item2,
}) => {
  return (
    <SquareContainer>
      <Typography variant="h3" color={textColor}>
        {title?.toUpperCase()}
      </Typography>
      <Item1Container>{item1}</Item1Container>
      <Item2Container>{item2}</Item2Container>
    </SquareContainer>
  );
};

export default SquareItem;
