import { FlexColumnBox } from '@components/templates';
import { Box, styled } from '@mui/material';

export const SquareContainer = styled(FlexColumnBox)(({ theme }) => ({
  padding: theme.spacing(2.5, 3),
  boxShadow: theme.shadows[1],
}));

export const Item1Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const Item2Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.75),
  alignSelf: 'flex-end',
}));
