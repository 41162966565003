import React from 'react';
import { ValueViewSectionProps } from './ValueViewSection.types';
import { IsoRiskMatrix } from '../IsoRiskMatrix';
import { PortfolioRiskGrading } from '../PortfolioRiskGrading';
import {
  TitleIsoRiskMatrix,
  TitleSection,
  ValueViewContainer,
} from './ValueViewSection.styles';
import { Box } from '@mui/material';

const ValueViewSection: React.FC<ValueViewSectionProps> = ({
  isDesktop,
  siteList,
  titleSection,
  titleIsoRiskMatrix,
  titleBottomAxisIsoRiskMatrix,
  titleLeftAxisIsoRiskMatrix,
  percentile90,
  YAxisIsoRiskMatrix,
  YAxisLabelIsoRiskMatrix,
  titleGraph,
  riskData,
  currency,
}) => {
  return (
    <>
      <TitleSection variant="h1">{titleSection}</TitleSection>
      <ValueViewContainer>
        {isDesktop && (
          <Box>
            <TitleIsoRiskMatrix variant="h5" textTransform={'uppercase'}>
              {titleIsoRiskMatrix}
            </TitleIsoRiskMatrix>
            <IsoRiskMatrix
              titleBottomAxis={titleBottomAxisIsoRiskMatrix}
              titleLeftAxis={titleLeftAxisIsoRiskMatrix}
              siteList={siteList}
              percentile90={percentile90}
              YAxis={YAxisIsoRiskMatrix}
              YLabelAxis={YAxisLabelIsoRiskMatrix}
              currency={currency}
            />
          </Box>
        )}
        <PortfolioRiskGrading titleGraph={titleGraph} riskData={riskData} />
      </ValueViewContainer>
    </>
  );
};

export default ValueViewSection;
