import { FlexBox } from '@components/templates';
import { Typography, styled } from '@mui/material';

export const TitleSection = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(5, 0, 1, 0),
  [theme.breakpoints.not('desktop')]: {
    margin: theme.spacing(5, 0, 2, 0),
  },
}));

export const TitleIsoRiskMatrix = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 5),
  [theme.breakpoints.not('desktop')]: {
    display: 'none',
  },
}));

export const ValueViewContainer = styled(FlexBox)(({ theme }) => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(5),
}));
