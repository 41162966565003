import { Box, Button, Typography, styled } from '@mui/material';

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),
  [theme.breakpoints.only('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const InfoItemContainer = styled(Box)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const InfoContent = styled(Box)(({ theme, borderBottom }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  alignItems: 'flex-start',
  flex: 1,
  minWidth: 200,
  [theme.breakpoints.only('mobile')]: {
    paddingBottom: theme.spacing(borderBottom ? 4 : 0),
    marginBottom: theme.spacing(borderBottom ? 1.5 : 0),
    borderBottom: borderBottom
      ? `2px solid ${theme.palette.border.main}`
      : 'none',
  },
  [theme.breakpoints.up('tablet')]: {
    borderLeft: `2px solid ${theme.palette.border.main}`,
    padding: theme.spacing(0, 3),
  },
}));

export const AdressSectionContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    width: '100%',
  },
}));

export const PreLineText = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));

export const InLineText = styled(Typography)(() => ({
  display: 'inline-block',
}));

export const OffsetLogoContainer = styled('span')(() => ({
  position: 'relative',
  top: 10,
  left: 10,
}));

export const WrongAddressButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 0),
  display: 'block',
  [theme.breakpoints.only('mobile')]: {
    width: '100%',
  },
}));
