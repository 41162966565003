import { Skeleton, Stack, styled } from '@mui/material';

export const PortfolioRiskListContent = styled(Stack)(() => ({
  display: 'flex',
  flex: 1,
  direction: 'column',
  gap: 8,
}));

export const ExplanationSquareSkeleton = styled(Skeleton)(() => ({
  width: '100%',
  height: 160,
}));

export const GraphContent = styled(Stack)(({ theme, isPortfolioView }) => ({
  [theme.breakpoints.only('desktop')]: {
    minHeight: isPortfolioView ? 452 : 0,
  },
}));
